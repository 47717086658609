import { post, deletes, get, downFile } from 'common/request'
export default {
  //  分页
  list: args => get('/scEssay/list', args),
  // 详情
  details: args => get(`/scEssay/getById?id=${args}`),
  // 新增公告
  addNotice: args => post('/scEssay/add', args),
  // 删除
  del: id => post(`/scEssay/delete?id=${id}`),
}
